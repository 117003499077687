*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
}

body {
  color: light-dark(black, white);
  background: light-dark(white, var(--mantine-color-dark-9));
  font-size: 16px;
  font-weight: 400;

  a {
    color: inherit;
    text-decoration: none;
  }

  h1 {
    font-size: 76px;
    font-weight: 800;
  }

  h2 {
    font-weight: 32px;
    font-weight: 700;
  }

  h3 {
    font-size: 24px;
    font-weight: 400;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
  }
}
